.map-container {
    width: 100%;
    height: 70vh;
  }

.pin-content{
  color: black;
  font-size: 14px;
}

.danger { 
  color: blue;
  
}