@import url("https://fonts.googleapis.com/css2?family=Inter:wght@400;500;600;700&display=fallback");

/* Additional styles */
@import "utility-patterns.css";
@import "utility-components.css";
@import "react-date-picker.css";

@tailwind base;
@tailwind components;
@tailwind utilities;

@layer base {
  :root {
    --color-brand: rgba(82, 170, 138, 1); /* main color*/
    --color-primary: rgba(47, 59, 82, 1);
    --color-secondary: rgba(94, 118, 162, 1);
    --color-tertiary: rgba(200, 210, 205, 1);
    --color-other: rgba(248, 250, 251, 1);

    --color-background: rgba(248, 250, 251, 1);
    --color-skeleton: rgba(47, 59, 82, 1);
    
    --color-warn: rgba(255, 204, 0, 1);
    --color-delete: rgba(255, 0, 0, 0.8);
    --color-disabled: rgb(75 85 99);


    --side-bar-color: rgba(255, 255, 255, 1);
    --side-bar-active: rgba(231, 231, 255, 1);
    --side-bar-text: rgba(37, 59, 82, 1);
    --side-bar-other: rgba(30, 41, 59, 1);
  }
  .dark-theme {
    --color-brand: rgba(129, 221, 152, 1);
    --color-primary: rgb(248, 250, 251);
    --color-secondary: rgb(120, 130, 155);
    --color-tertiary: rgba(94, 118, 162, 1);
    --color-other: rgba(47, 59, 82, 1);

    --color-background: rgba(36, 46, 66, 1);
    --color-skeleton: rgb(219, 207, 207);

    --side-bar-color: rgba(30, 41, 59, 1);
    --side-bar-active: rgba(37, 59, 82, 1);
    --side-bar-text: rgba(203, 213, 225, 1);
    --side-bar-other: rgba(255, 255, 255, 1);
  }
  body {
    @apply bg-background;
  }

  html {
    scroll-behavior: smooth;
  }

  .dropdown-arrow {
    @apply w-3 h-3 shrink-0 ml-1 fill-current text-sidebar-link opacity-70;
  }
  .sidebar-icon {
    @apply w-8 h-8 shrink-0 opacity-90;
  }
  .sidebar-item {
    @apply block text-sidebar-link truncate transition duration-150;
  }

  .sidebar-active {
    @apply bg-sidebar-active;
  }
  .sidebar-link-name {
    @apply ml-3 text-sm  text-sidebar-other font-bold underline-offset-4;
  }
  .sidebar-li {
    @apply px-3 py-2  mb-0.5 last:mb-0 transition hover:translate-x-1 hover:bg-sidebar-active;
  }
  .sidebar-subitem-name {
    @apply text-sm font-medium;
  }

  .btn {
    @apply text-sm px-3 py-2 font-medium tracking-wide focus:outline-none focus:ring focus:ring-brand focus:ring-opacity-80;
  }
  .btn-primary {
    @apply btn text-other capitalize bg-brand rounded-md hover:opacity-90;
  }
  .btn-secondary {
    @apply btn text-primary capitalize  rounded-md;
  }

  .btn-group {
    @apply inline-flex mr-1 rounded-md shadow-sm
    }

  .btn-group button {
    @apply btn text-primary text-xxs bg-secondary;
  }

  .btn-warn {
    @apply btn text-other capitalize bg-warn rounded-md hover:opacity-90;
  }

  .btn-delete {
    @apply btn text-other capitalize bg-delete rounded-md hover:opacity-90;
  }

  .header-nav-btn{
    @apply inline-flex items-center justify-center w-full gap-2 btn-primary
  }


  .section-container {
    @apply flex flex-col gap-2 py-4 px-8  shadow bg-other text-primary;
  }


  .speedometer-container {
    @apply flex justify-center items-center flex-col gap-4;
  }
  .alert-status {
    @apply capitalize absolute right-0 p-1 px-3 text-sm rounded-bl-full  -top-0 text-other;
  }
  .filter-nav {
    @apply flex flex-col items-center gap-4 md:flex-row text-brand;
  }

  .input-field {
    @apply bg-background border-2 border-secondary text-primary text-sm rounded-lg focus:ring-brand focus:border-brand block w-full p-2.5 autofill:bg-background;
  }

  .input-field-filter {
    @apply bg-gray-50 border border-[#2F3B52] text-sidebar-active text-sm rounded-lg focus:ring-brand focus:border-brand block w-full p-2.5 py-1.5 accent-brand outline-brand outline-0;
  }
  .multi-input-field-filter {
    @apply border-[#2F3B52]  text-sm  rounded-sm focus:ring-brand focus:border-brand accent-brand outline-brand outline-0 z-10;
  }

  input::placeholder {
    color: text-brand
  }



  .pagination-top-link {
    @apply relative z-10 inline-flex items-center  px-4 py-1 text-sm rounded-lg font-medium text-primary focus:z-20 hover:cursor-pointer;
  }
  .pagination-bottom-link {
    @apply relative z-10 inline-flex items-center  px-4 py-1 text-sm font-medium text-primary focus:z-20 hover:cursor-pointer;
  }
  .header-justify-between {
    @apply flex items-center justify-between px-5 py-2 rounded-sm shadow-lg bg-other col-span-full xl:col-span-8;
  }

  .scroll-to {
    @apply p-0 m-0 box-border bg-green-700 fixed bottom-5 right-3 h-16 w-16 grid place-items-center rounded-full shadow-md cursor-pointer;
  }

  #scroll-to-container {
    @apply fixed bottom-24 right-10 h-16 w-16 rounded-full grid place-items-center shadow-md cursor-pointer z-20;
  }

  #scroll-to-content {
    @apply h-4/5 w-4/5 bg-white rounded-full grid place-items-center text-4xl text-blue-900 z-auto;
  }

  #scroll-to-content-safari {
    @apply h-4/5 w-4/5 bg-white rounded-full grid place-items-center text-xs text-blue-900 z-auto;
  }
}


/* Change Autocomplete styles in Chrome*/
input:-webkit-autofill,
input:-webkit-autofill:hover,
input:-webkit-autofill:focus,
textarea:-webkit-autofill,
textarea:-webkit-autofill:hover,
textarea:-webkit-autofill:focus,
select:-webkit-autofill,
select:-webkit-autofill:hover,
select:-webkit-autofill:focus {
  border: 0px solid var(--color-background);
  -webkit-text-fill-color: var(--color-primary);
  -webkit-box-shadow: 0 0 0px 1000px var(--color-background) inset;
  transition: background-color 5000s ease-in-out 0s;
}
