.modal {
  position: fixed;
}

.modal-overlay {
  cursor: pointer;
}

.modal-header {
  position: sticky;
  top: 0;
  z-index: 1;
  display: flex;
  justify-content: flex-end;
  align-items: center;
  margin-bottom: 10px;
  background-color: var(--color-background);
  padding: 20px;
  border-radius: 8px;
}

.modal-header button {
  margin: 0;
  padding: 0;
}

.modal-content {
  position: relative;
  max-width: 60vw;
  margin: 80px auto;
  background-color: var(--color-background);
  padding: 20px;
  border-radius: 8px;
}

.close {
  font-size: 24px;
  cursor: pointer;
  color: black;
}

.download-button {
  font-size: 24px;
  cursor: pointer;
  color: black;
  background: none;
  border: none;
}

.modal-media {
  max-width: 100%;
  height: auto;
}

.modal.maximised {
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  margin: 0;
  border-radius: 0;
}

.modal.maximised .modal-header {
  padding: 20px;
}

.modal.maximised .modal-content {
  margin: 0;
  padding: 20px;
  max-width: 80vw;
  max-height: 80vh;
  width: auto;
  height: auto;
  position: fixed;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}

.modal.maximised .modal-media {
  max-width: 80vw;
  max-height: 80vh;
  width: auto;
  height: auto;
  position: fixed;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  background-color: var(--color-background);
  padding: 20px;
  border-radius: 8px;
}

.left-chevron,
.right-chevron {
  position: fixed;
  top: 50%;
  transform: translateY(-50%);
  font-size: 24px;
  cursor: pointer;
  color: black;
}

.left-chevron {
  left: 10px;
}

.right-chevron {
  right: 10px;
}